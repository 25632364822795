import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
    return (
        <>
            <div className="login-section d-flex flex-column justify-content-center min-vh-100 py-5">
                <div className="container text-center">
                    <h1 className="display-1 fw-semibold mb-2 text-main text-center">404</h1>
                    <h5 className="mb-5 text-black text-center">Oops! Page Not Found</h5>
                    <Link to="/" className="btn btn1">Return To Home Page</Link>
                </div>
            </div>
        </>
    );
}

export default ErrorPage;
