import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import banner1 from "../assets/images/img7.jpg";
import banner2 from "../assets/images/img2.webp";
import banner3 from "../assets/images/img3.jpg";
import CallToAction from "../components/CallToAction";
import { FaBookOpen, FaHandHoldingHeart } from "react-icons/fa";
const Home = () => {
  return (
    <>
      <Header />

      <div className="banner-section">
        <Swiper
          modules={[Pagination, Autoplay, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
        >
          <SwiperSlide
            className="d-flex flex-column justify-content-center"
            style={{ backgroundImage: `url(${banner1})` }}
          >
            <div className="overlay d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-9 col-12 text-center text-lg-start">
                    <p className="text-main mb-2">Monark Foundation for</p>
                    <h1 className="text-white display-2 fw-600 mb-3">
                      Sustainable Community Development and{" "}
                      <span className="text-main">Empowerment</span>
                    </h1>
                    <p className="text-white mb-3 fs-6">
                      Empowering Lives, Transforming Futures: Together, We Build
                      a Better World"
                    </p>
                    <Link
                      to="https://forms.gle/i25fLHV7N1u3pgzSA"
                      className="btn btn1 mt-3 mt-sm-4"
                    >
                      <span>Join Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="d-flex flex-column justify-content-center"
            style={{ backgroundImage: `url(${banner2})` }}
          >
            <div className="overlay d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-9 col-12 text-center text-lg-start">
                    <p className="text-main mb-2">Monark Foundation</p>
                    <h1 className="text-white display-2 fw-600 mb-3">
                      Empowering Communities for a{" "}
                      <span className="text-main">Sustainable Future</span>
                    </h1>
                    <p className="text-white mb-3 fs-6">
                      Temporibus unde quo quaerat totam deserunt delectus error
                      animi similique nemo vitae
                    </p>
                    <Link
                      to="https://forms.gle/i25fLHV7N1u3pgzSA"
                      className="btn btn1 mt-3 mt-sm-4"
                    >
                      <span>Join Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="d-flex flex-column justify-content-center"
            style={{ backgroundImage: `url(${banner3})` }}
          >
            <div className="overlay d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-9 col-12 text-center text-lg-start">
                    <p className="text-main mb-2">Monark Foundation</p>
                    <h1 className="text-white display-2 fw-600 mb-3">
                      Empowering Communities{" "}
                      {/*Through Collaborative Sustainable*/}{" "}
                      <span className="text-main">Development Initiatives</span>
                    </h1>
                    <p className="text-white mb-3 fs-6">
                      Creating Lasting Impact, Inspiring Change: Building a
                      Brighter Future for All
                    </p>
                    <Link
                      to="https://forms.gle/i25fLHV7N1u3pgzSA"
                      className="btn btn1 mt-3 mt-sm-4"
                    >
                      <span>Join Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="section1 padding">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-5">
              <img
                src={require("../assets/images/img4.jpg")}
                className="img-fluid rounded-3 w-100"
                style={{ minHeight: "100%", objectFit: "cover" }}
              />
            </div>

            <div className="col-lg-6 ms-auto align-self-center">
              <div className="py-lg-5">
                <h1 className="display-4 fw-600 mb-4 text-black">
                  Welcome to{" "}
                  <span className="text-main">Monark Foundation</span>
                </h1>

                <p className="">
                  At Monark Foundation, we believe in the power of collective
                  action to address the most pressing challenges facing society
                  today. Through our key initiatives in social activities,
                  community-led events, education, healthcare, environmental
                  conservation, and poverty alleviation, we strive to create
                  lasting impact and meaningful change. By engaging with local
                  stakeholders, partnering with like-minded organizations, and
                  leveraging cutting-edge solutions, we aim to build resilient
                  communities that are equipped to overcome adversity and
                  embrace opportunities for growth.
                </p>

                <div className="">
                  <Link
                    to="/about"
                    className="btn btn1 w-100 mt-3 mt-md-4"
                    style={{ maxWidth: "220px" }}
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-10 mx-auto text-center">
              <h1 className="mb-4 display-5 fw-600 text-black">
                Our Programes
              </h1>
            </div>
          </div>

          <div className="row gy-5 justify-content-center">
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">Social Activities</h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">Community-led Events</h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">Regional Programmes</h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaBookOpen className="fs-2" />
                </div>
                <h5 className="fw-600">Education and Skill Development</h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">
                  Healthcare and Public Health Initiatives
                </h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">Environmental Conservation</h5>
                <p className="mb-0"></p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-3">
              <div className="services-box text-center">
                <div className="icon">
                  <FaHandHoldingHeart className="fs-2" />
                </div>
                <h5 className="fw-600">
                  Poverty Alleviation and Sustainable Development
                </h5>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallToAction />

      <Footer />
    </>
  );
};

export default Home;
