import React from "react";
import { FaCalendarAlt, FaRegClock } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";

const OurTeam = () => {
  return (
    <div className="section1 padding">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-4 mx-auto col-md-6 col-12">
            <h1 class="mb-4 display-5 fw-600 text-black text-center">
              Our Teams
            </h1>{" "}
            {/* Middle box */}
            <div className="program-box">
              <div className="program">
                <a href="#">
                  <img
                    src={require("../assets/images/img8.png")}
                    className="img-fluid w-full h-full object-cover"
                  />
                </a>
              </div>
              <div className="txt">
                <h4 className="mb-2 fw-600">Shree Mohanbharti Goswami</h4>
                <p className="mb-3 fs-14">Chairman Monark Foundation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-4">
          <div className="col-lg-4 col-md-6 col-12">
            {" "}
            {/* The first box in the second row */}
            <div className="program-box">
              <div className="program">
                <a href="#">
                  <img
                    src={require("../assets/images/img6.png")}
                    className="img-fluid w-full h-full object-cover"
                  />
                </a>
              </div>
              <div className="txt">
                <h4 className="mb-2 fw-600">Monark H. Goswami</h4>
                <p className="mb-3 fs-14">Founder Monark Foundation</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12">
            {/* The second box in the second row */}
            <div className="program-box relative">
              <div className="program h-full">
                <a href="#">
                  <img
                    src={require("../assets/images/img1.jpg")}
                    className="absolute inset-0 w-full h-full object-cover"
                    alt="Program Image"
                  />
                </a>
              </div>{" "}
              <div className="txt">
                <h5 className="mb-2 fw-600">Dr. Hasmukhbharthi M. Goswami</h5>
                <p className="mb-3 fs-14">President Monark Foundation</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12">
            {" "}
            {/* The third box in the second row */}
            <div className="program-box">
              <div className="program">
                <a href="#">
                  <img
                    src={require("../assets/images/img5.jpg")}
                    className="img-fluid w-full h-full object-cover"
                  />
                </a>
              </div>
              <div className="txt">
                <h4 className="mb-2 fw-600">Dr. Satishbharthi M. Goswami</h4>
                <p className="mb-3 fs-14">Vice President Monark Foundation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
