import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import bannerImg from "../assets/images/img3.jpg";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsEnvelope, BsPhone } from "react-icons/bs";

const Contact = () => {
  return (
    <>
      <Header />

      <div
        className="banner-about"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="overlay padding d-flex flex-column justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <h1 className="mb-3 display-4 fw-600 text-white">Contact Us</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding">
        <div className="container">
          <div className="">
            <div className="">
              <h3 className="contact-info-text mb-4 text-black fw-600 text-center">
                Contact Info
              </h3>
              <div className="contact-grp d-flex align-items-center justify-content-center">
                <div className="mb-4 d-flex">
                  <div className="me-2">
                    <HiOutlineLocationMarker className="me-1 text-main fs-4" />
                  </div>
                  <div>
                    <h5 className="mb-2 fw-semibold text-black">Address</h5>
                    <p className="mb-0">
                      {" "}
                      Monark Foundation - Monark University <br />{" "}
                      at vehlal, Ahmedabad  3823300
                    </p>
                  </div>
                </div>

                <div className="mb-4 d-flex">
                  <div className="me-2">
                    <BsEnvelope className="me-1 text-main fs-4" />
                  </div>
                  <div>
                    <h5 className="mb-2 fw-semibold text-black">Email</h5>
                    <p className="mb-1">
                      <a href="#">monarkfoundation150@gmail.com</a>
                    </p>
                  </div>
                </div>

                <div className="mb-4 d-flex">
                  <div className="me-2">
                    <BsPhone className="me-1 text-main fs-4" />
                  </div>
                  <div>
                    <h5 className="mb-2 fw-semibold text-black">Phone</h5>
                    <p className="mb-1">
                      <a href="#">+91 87349 33493</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12 mt-4 mt-lg-0 align-self-center ms-auto">
              {/* <div className="contact-form">
                                <h3 className="mb-4 text-black fw-600">Get in Touch</h3>
                                <form action="" className="form form1">
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Full Name" />
                                    </div>
                                    <div className="mb-4">
                                        <input type="email" className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Phone" />
                                    </div>
                                    <div className="mb-4">
                                        <textarea name="" id="" className="form-control" placeholder="Message" style={{height: '120px'}}></textarea>
                                    </div>
                                    <div className="">
                                        <button className="btn btn1">Send Now</button>
                                    </div>
                                </form>
                            </div> */}
            </div>
          </div>

          <div className="mt-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14681.41134742937!2d72.757551!3d23.0841762!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e7d97f82d063b%3A0x96b95db56251dca8!2sMonark%20University.!5e0!3m2!1sen!2sin!4v1714296301122!5m2!1sen!2sin"
              frameborder="0"
              class="iframe w-100"
              style={{ border: "0px" }}
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
