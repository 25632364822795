import React from 'react'
import { Link } from 'react-router-dom'

import callImg from '../assets/images/img3.jpg'

const CallToAction = () => {
    return (
        <>
            <div className="call-section" style={{backgroundImage: `url(${callImg})`}}>
                <div className="overlay padding d-flex flex-column justify-content-center">
                    <div className="container">
                        <div className="call-to-div py-4">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <h1 className="display-4 fw-600 mb-4 text-white">Monark Foundation: Empowering Communities for a <span className='text-main'>Sustainable Future</span>.</h1>
                                    <p className="text-white mb-0">Building Resilient Communities, Transforming Lives</p>
                                </div>
                                <div className="col-lg-4 ms-auto text-lg-end">
                                    <div className="">
                                        <Link to="https://forms.gle/i25fLHV7N1u3pgzSA" className="btn btn1 w-100 mt-4 mt-lg-0" style={{maxWidth: '220px'}}>Call To Action</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallToAction
