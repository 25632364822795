import React from "react";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Header from "../components/Header";
import OurTeam from "../components/OurTeam";
import { Link } from "react-router-dom";
import bannerImg from "../assets/images/img3.jpg";

const About = () => {
  return (
    <>
      <Header />

      <div
        className="banner-about"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="overlay padding d-flex flex-column justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <h1 className="mb-3 display-4 fw-600 text-white">About Us</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section1 padding">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-5">
              <img
                src={require("../assets/images/img4.jpg")}
                className="img-fluid rounded-3 w-100"
                style={{ minHeight: "100%", objectFit: "cover" }}
              />
            </div>

            <div className="col-lg-6 ms-auto align-self-center">
              <div className="py-lg-5">
                <h1 className="display-4 fw-600 mb-4 text-black">
                  About <span className="text-main">Monark Foundation</span>
                </h1>

                <p className="">
                  Key Initiatives of Monark Foundation: Social Activities,
                  Community-led Events, Regional Programmes, Education and Skill
                  Development, Healthcare and Public Health Initiatives,
                  Environmental Conservation, Poverty Alleviation and
                  Sustainable Development
                </p>
                <p className="">
                  <b>Mission: </b> "At Monark Foundation, we are dedicated to
                  fostering positive change and sustainable development within
                  communities through comprehensive initiatives in social
                  activities, education, healthcare, environmental conservation,
                  and poverty alleviation. Our mission is to empower individuals
                  and communities to thrive, creating a brighter and more
                  equitable future for all."
                </p>
                <p>
                  <b>Vision: </b>"Our vision at Monark Foundation is to build
                  resilient and inclusive communities where every individual has
                  access to quality education, healthcare, and essential
                  resources. Through collaborative efforts and innovative
                  solutions, we aspire to be a catalyst for positive social
                  transformation, leading to a world where everyone can reach
                  their full potential and live in harmony with the environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CallToAction /> */}
      <OurTeam />
      <Footer />
    </>
  );
};

export default About;
