import React, { createContext, useContext, useReducer } from 'react';
import reducer from './AllReducer';
import axios from 'axios';

const AllContext = createContext()

const initialState = {
    showPasswords: [],
    images: []
}

const AllContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const handleTogglePasswordVisibility = (index) => {
        const newShowPasswords = [...state.showPasswords];
        newShowPasswords[index] = !newShowPasswords[index];

        dispatch({ type: "setShowPasswords", payload:  newShowPasswords})
    };

    const getImages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/send-images`)
            const data = await response.data

            dispatch({ type: "setImages", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }


    return <AllContext.Provider value={{...state, handleTogglePasswordVisibility, getImages}}>
        {children}
    </AllContext.Provider>
}

const useAllContext = () => {
    return useContext(AllContext)
}

export { AllContextProvider, useAllContext };