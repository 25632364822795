
const reducer = (state, action) => {
    switch (action.type) {
        case "setShowPasswords":
            return {
                ...state,
                showPasswords: action.payload
            }

        case "setImages":
            return {
                ...state,
                images: action.payload.data
            }

        default:
            return state
    }

}

export default reducer