import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import bannerImg from '../assets/images/img3.jpg'
import { Link } from 'react-router-dom'
import Fancybox from "../components/Fancybox";
import { useAllContext } from '../context/AllContext'
import one from '../assets/images/gallery/one.jpg'
import two from '../assets/images/gallery/two.jpg'
import three from '../assets/images/gallery/three.jpg'
import four from '../assets/images/gallery/four.jpg'
import five from '../assets/images/gallery/five.jpg'
import six from '../assets/images/gallery/six.jpg'

const Gallery = () => {

    // const { getImages, images } = useAllContext()
    // useEffect(() => {
    //     getImages()
    // }, [])

    const images = [one, two, three, four, five, six];

    return (
        <>
            <Header />
            <div className="banner-about" style={{ backgroundImage: `url(${bannerImg})` }}>
                <div className="overlay padding d-flex flex-column justify-content-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto text-center">
                                <h1 className="mb-3 display-4 fw-600 text-white">Gallery</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center mb-0">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-10 mx-auto text-center">
                            <h1 className="mb-4 display-5 fw-600 text-black">Our Works</h1>
                        </div>
                    </div>
                    <Fancybox options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}>
                        <div className="row g-4">
                            {
                                images && images.length ? images.map((curItems, ind) => {
                                    console.log(curItems);
                                    return <div className="col-lg-4 col-md-6 col-12">
                                        <div className="gallery-box">
                                                <img src={curItems} className="img-fluid w-100" />
                                                <div className="icon">
                                                    <i className="fa-solid fa-plus"></i>
                                                </div>
                                        </div>
                                    </div>
                                })
                                : <p className='text-center my-5'>No Records</p>
                            }
                            
                        </div>
                    </Fancybox>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery
