import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <div className="bg-white p-2 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
              <img
                src={require("../assets/images/logo.png")}
                alt="Logo"
                className="img-fluid logo1 rounded-lg hover:brightness-125"
              />
            </div>
          </Link>

          <div className="order-lg-1 ms-auto ms-lg-3">
            <Link
              to="https://forms.gle/i25fLHV7N1u3pgzSA"
              className="btn btn1 text-nowrap"
            >
              Join Now
            </Link>
          </div>
          <button
            className="navbar-toggler ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              {/* <li className="nav-item">
                                <NavLink className="nav-link" to="/programs">Programs</NavLink>
                            </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery">
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
