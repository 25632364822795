import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsEnvelope, BsPhone } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="footer-section">
        <div className="padding">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-12">
                <img
                  src={require("../assets/images/logo.png")}
                  alt=""
                  className="img-fluid logo-foot mb-4"
                />
              </div>

              <div className="col-lg-2 col-md-6 col-12 ms-auto">
                <h5 className="text-main fw-600 fs-18 mb-3">Links</h5>
                <nav className="nav flex-column footer-nav">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                  <Link className="nav-link" to="/about">
                    About
                  </Link>

                  <Link className="nav-link" to="/gallery">
                    Gallery
                  </Link>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </nav>
              </div>
              <div className="col-lg-2 col-md-6 col-12"></div>
              <div className="col-lg-3 col-12">
                <h5 className="text-main fw-600 fs-18 mb-3">Contact</h5>
                <p className="">
                  <HiOutlineLocationMarker className="me-1 text-main fs-6" />{" "}
                  Monark Foundation - Monark University
                  at vehlal, Ahmedabad  382330
                </p>
                <p className="">
                  <BsEnvelope className="me-1 text-main fs-6" />{" "}
                  <Link to="mailTo:">monarkfoundation150@gmail.com</Link>
                </p>
                <p className="">
                  <BsPhone className="me-1 text-main fs-6" />{" "}
                  <Link to="tel:">+91 87349 33493</Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer2">
          <div className="container">
            <div className="row g-3 align-items-center">
              <div className="col-lg-7 col-12 order-lg-1">
                <nav className="nav social justify-content-center justify-content-lg-end">
                  <Link
                    className="nav-link"
                    to="https://www.facebook.com/profile.php?id=61558877223401&mibextid=ZbWKwL"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    className="nav-link"
                    to="https://www.instagram.com/monarkfoundation/?igsh=MTh0YWlwMTd4cWJx"
                  >
                    <FaInstagram />
                  </Link>
                  <Link
                    className="nav-link"
                    to="https://www.linkedin.com/company/monark-foundation/?viewAsMember=true"
                  >
                    <FaLinkedinIn />
                  </Link>
                </nav>
              </div>
              <div className="col-lg-5 col-12 text-center text-lg-start">
                <p className="mb-0 text-white">
                  Copyright &copy; 2024 Monark Foundation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
