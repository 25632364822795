import React from "react";

import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import './assets/css/style.css';
import './assets/css/responsive.css';

import 'react-toastify/dist/ReactToastify.css';

import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import ErrorPage from "./views/ErrorPage";
import { ToastContainer } from "react-toastify";

import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import Gallery from "./views/Gallery";


function App() {
  return (
    <>
      <Routes>
        
        <Route exect path="/" element={<Home />} />
        <Route exect path="/about" element={<About />} />
        <Route exect path="/gallery" element={<Gallery />} />
        {/* <Route exect path="/programs" element={<Programs />} /> */}
        {/* <Route exect path="/join" element={<Join />} /> */}
        <Route exect path="/contact" element={<Contact />} />

        <Route path='*' element={<ErrorPage />} />

      </Routes>

      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
